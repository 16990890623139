require('../lib/isteven-multi-select');
const uiSelect = require('ui-select');

require('angular')
    .module('RealEstateFilter', ['isteven-multi-select', uiSelect])
    .config(/*@ngInject*/ function ($locationProvider, uiSelectConfig) {
        // potrebne pre filter
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
        uiSelectConfig.theme        = 'selectize';
		uiSelectConfig.skipFocusser = true;

        // ak linky nemaju nastaveny ziadny target, tak $locationProvider.html5Mode blokuje tie linky
        // nastavenie target '_self' pre linky, ktore nemaju target
        $(document).ready(function () {
            $('a').each(function() {
                const link = $(this);

                if (!link.is('[target]') && !link.is('[ng-href]')) {
                    link.attr('target', '_self');
                }
            });
        })
    })
    .controller('FilterController', require('./controller'))
    .service('RealEstateSpinner', require('./spinner'))
    .factory('FilterProvider', require('./provider'));

module.exports = 'RealEstateFilter';