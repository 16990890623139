module.exports = /*@ngInject*/ function ($http, $cacheFactory, $q) {
    const cache = $cacheFactory('advertFilter');
    const Provider = {
        loadAmount     : loadAmount,
        loadSpecies    : loadSpecies,
        loadTypes      : loadTypes,
        loadStates     : loadStates,
        loadCountries  : loadCountries,
        loadRegions    : loadRegions,
        loadCities     : loadCities,
        loadCategories : loadCategories,
        loadLocationText : loadLocationText
    };

    function loadAmount(filterData) {
        return $http({
            method: 'POST',
            url: '/realestate/amount',
            data: {
                filter: filterData
            }
        }).then(function (response) {
            return response.data.total;
        });
    }

    function loadSpecies() {
        return $http({
            method: 'POST',
            url: '/realestate/params/specie'
        }).then(function (response) {
            return response.data.species;
        });
    }

    function loadTypes() {
        return $http({
            method: 'POST',
            url: '/realestate/params/type'
        }).then(function (response) {
            return response.data.types;
        });
    }

    function loadStates() {
        return $http({
            method: 'POST',
            url: '/realestate/location/state'
        }).then(function (response) {
            return response.data.states;
        });
    }

    function loadCountries(stateId) {
        return $http({
            method: 'POST',
            url: '/realestate/location/country',
            data: {
                state: stateId
            }
        }).then(function (response) {
            return response.data.countries;
        });
    }

    function loadRegions(countryId) {
        return $http({
            method: 'POST',
            url: '/realestate/location/region',
            data: {
                country: countryId
            }
        }).then(function (response) {
            return response.data.regions;
        });
    }

    function loadCities(regions) {
        return $http({
            method: 'POST',
            url: '/realestate/location/city',
            data: {
                regions: regions
            }
        }).then(function (response) {
            return response.data.cities;
        });
    }

    function loadLocationText(locationText) {
        return $http({
            method: 'POST',
            url: '/realestate/location/locations',
            data: {
                locationText: locationText
            }
        }).then(function (response) {
            return response.data.locationText;
        });
    }

    function loadCategories(specie) {
        var defered = $q.defer();

        if (cache.get(specie)) {
            defered.resolve(cache.get(specie));
        } else {
            $http({
                method: 'POST',
                url: '/realestate/params/categories',
                data: {
                    specie: specie
                }
            }).then(function (response) {
                cache.put(specie, response.data.categories);

                defered.resolve(response.data.categories);
            }, function () {
                defered.reject();
            });
        }

        return defered.promise;
    }

    return Provider;
};
