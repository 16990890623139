module.exports = /*@ngInject*/ function ($q) {
	const promises = [];
	const self     = this;

	this.addPromise = addPromise;
	this.proccess = {
		loading: false
	};

	function addPromise(promise) {
		promises.push(promise);

		if (!self.proccess.loading) {
			processPromises();
		}
	}

	function processPromises() {
		self.proccess.loading = true;

		if (promises.length > 0) {
			const promise = promises.shift();

			promise.finally(function () {
				processPromises();
			});
		} else {
			self.proccess.loading = false;
		}
	}
};